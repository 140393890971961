



























import Vue from 'vue';
import CreateMembershipModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    isLoading: false,
    errorResponse: undefined as any,
  }),
  computed: {
    disableAddressForm(): boolean {
      return this.innerValue.isMemberContactReadonly!;
    },
    birthdateRequired(): boolean {
      // This is needed because dynamic required fields in schema can not be evaluated yet
      // This must match schema in 5.schema.ts
      const { isCompany } = this.innerValue.memberContact.address;
      const isEinzelunternehmen = this.innerValue.memberContact.address.companyLegalFormId === this.$config.values['gs-business-legal-form-einzelunternehmen-lookup-id'];
      return !isCompany || isEinzelunternehmen;
    },
    languageItems(): {caption: string, id: string}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.german'),
          id: 'DE',
        },
        {
          caption: this.$t('page.createMembership.select.french'),
          id: 'FR',
        },
        {
          caption: this.$t('page.createMembership.select.italian'),
          id: 'IT',
        },
      ];
    },
    innerValue: {
      get(): CreateMembershipModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    if (!this.innerValue.memberContact.address.eMail) {
      this.innerValue.memberContact.address.eMail = this.$auth.user?.email as string;
    }
    this.isLoading = false;
  },
});
